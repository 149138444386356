import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';


// Create style classes for entire component
const useStyles = makeStyles(() => ({
  completionRatesPage: {
    boxSizing: 'border-box',
    padding: '10px 0px',
    overflowY: 'auto',
    height: 'calc(100vh - 64px)'
  },
  accordionCont: {
    maxWidth: '550px',
    margin: '0 auto'
  },
  paper: {
    backgroundColor: 'white',
    width: 'fit-content',
    padding: '10px',
    margin: '0 auto 20px auto',
    borderRadius: '7px',
    boxShadow: '1px 1px 2px 0px rgba(0,0,0,0.5)'
  },
  summary: {
    paddingLeft: '0',
  },
  selected: {
    border: '1px solid gold',
    margin: '0 !important'
  },
  unselected: {
    border: '1px solid rgb(150,150,150)',
  },
  accordion: {
    flexDirection: 'column',
    padding: '8px 4px',
    borderRadius: '0px 0px 5px 5px',
    backgroundColor: 'rgba(167,169,20,0.02)',
    minWidth: '292px'
  },
  heading: {
    flexBasis: '100%',
    flexShrink: 0,
    textAlign: 'left',
    paddingLeft: '20px'
  },
  tableContainer: {
    backgroundColor: 'white',
    border: '1px solid rgb(150,150,150)',
    maxWidth: 550,
    overflow: 'auto !important'
  },
  tHead: {
    padding: '2px 4px',
    textAlign: 'center'
  }
}));


// Function to get list of contests and users by making a request to the cloudflare worker endpoint
async function getCompletionRates(domain, obj) {
  let results = fetch(`//${domain}/getCompletionRates`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
  })
    .then(data => data.json())
    .catch(e => {
      console.error(e);
      return { error: true, msg: 'An error has occurred. Please try again later.', results: {} };
    });

  return results;
}




export default function CompletionRates({ user, token, isAdmin, setLoading, domain, setError }) {
  const [contests, setContests] = useState({});
  const [expandedContest, setExpandedContest] = useState(false);
  
  const classes = useStyles();

  // Get the list of contests and users
  useEffect(() => {
    if (isAdmin) {
      setLoading(true);
      getCompletionRates(domain, { user, token })
        .then(results => {
          if (results.error) {
            setError(false);
            setError(results.msg);
          }

          setContests(results.results);          
          setLoading(false);
        });
    }
  }, []);

  // Handles choosing a different user accordion
  const handleContestChange = (panel) => (event, isExpanded) => {
    setExpandedContest(isExpanded ? panel : false);
  };

  const renderUserList = (userList) => {
    return (
      <TableContainer className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tHead} component="th" scope="row" rowSpan={2}><Typography variant='h5'>User Name</Typography></TableCell>
              <TableCell className={classes.tHead} component="th" scope="row" colSpan={2}><Typography variant='h5'>Forecasts</Typography></TableCell>
              <TableCell className={classes.tHead} component="th" scope="row" colSpan={4}><Typography variant='h5'>Skill Scores</Typography></TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tHead} component="th" scope="row"><Typography variant='h5'>Made</Typography></TableCell>
              <TableCell className={classes.tHead} component="th" scope="row"><Typography variant='h5'>Missed</Typography></TableCell>
              <TableCell className={classes.tHead} component="th" scope="row"><Typography variant='h5'>Temp. Max</Typography></TableCell>
              <TableCell className={classes.tHead} component="th" scope="row"><Typography variant='h5'>Temp. Min</Typography></TableCell>
              <TableCell className={classes.tHead} component="th" scope="row"><Typography variant='h5'>Precip.</Typography></TableCell>
              <TableCell className={classes.tHead} component="th" scope="row"><Typography variant='h5'>Overall</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              userList.map(userObj =>
                <TableRow key={userObj.user}>
                  <TableCell><Typography variant='h5'>{userObj.user}</Typography></TableCell>

                  <TableCell align="center">{userObj.made}/{userObj.possible}<br/>({userObj.percentComplete}%)</TableCell>
                  <TableCell align="center">{userObj.missed}/{userObj.possible}<br/>({userObj.percentMissed}%)</TableCell>

                  <TableCell align="center">{userObj.skills.max}</TableCell>
                  <TableCell align="center">{userObj.skills.min}</TableCell>
                  <TableCell align="center">{userObj.skills.precip}</TableCell>
                  <TableCell align="center">{userObj.skills.overall}</TableCell>
                </TableRow>
              )
            }
          </TableBody>
        </Table>
      </TableContainer>
    );
  };


  return (
    <div className={classes.completionRatesPage}>
      <Typography className={classes.paper} variant='h2'>All Current and Completed Contests</Typography>
      <div className={classes.accordionCont}>
        {Object.keys(contests).length > 0 ?
          Object.keys(contests).map( cName => {
            return (
              <Accordion
                key={cName}
                className={expandedContest === cName ? classes.selected : classes.unselected}
                expanded={expandedContest === cName}
                onChange={handleContestChange(cName)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className={classes.summary}
                >
                  <Typography variant='h2' className={classes.heading}>{cName}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordion}>
                  {
                    expandedContest === cName && renderUserList(contests[cName])
                  }
                </AccordionDetails>
              </Accordion>
            );
          }) :
          <div className={classes.paper}>An error occurred while fetching the contests and users. Please try again later.</div>
        }
      </div>
    </div>
  );
}

CompletionRates.propTypes = {
  user: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  domain: PropTypes.string.isRequired,
  setError: PropTypes.func.isRequired
};
