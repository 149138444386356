import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  formatISO,
  eachDayOfInterval,
  parseISO,
  isBefore,
  isSameDay,
  addHours,
  isWeekend,
  getDay,
  getWeek,
  subDays,
  nextMonday,
  addDays,
  format,
  clamp,
  differenceInWeeks,
  differenceInCalendarDays
} from 'date-fns';

import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Typography
} from '@material-ui/core';

import Day from './Day';


// Create style classes for entire component
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  summary: {
    paddingLeft: '0',
  },
  selected: {
    border: '1px solid rgba(150,0,0,0.3)',
    borderRadius: '6px',
  },
  weekSelected: {
    border: '1px solid gold',
    margin: '0 !important'
  },
  unselected: {
    border: '1px solid rgb(150,150,150)',
  },
  accordion: {
    flexDirection: 'column',
    padding: '8px 4px',
    borderRadius: '0px 0px 5px 5px',
    backgroundColor: 'rgba(167,169,20,0.02)',
    minWidth: '292px'
  },
  weekAccordion: {
    flexDirection: 'column',
    padding: '0px 0px 8px 0px',
    minWidth: '292px'
  },
  primaryHeading: {
    flexBasis: '100%',
    flexShrink: 0,
    textAlign: 'left',
    paddingLeft: '20px'
  },
  heading: {
    flexBasis: '100%',
    flexShrink: 0,
  },
  secondaryHeading: {
    color: theme.palette.text.secondary,
    flexBasis: '65%',
    flexShrink: 0
  },
  weekHeading: {
    flexBasis: '25%',
    flexShrink: 0
  },
  weekPanel: {
    margin: '0 auto',
    paddingLeft: '52px'
  },
  paper: {
    backgroundColor: 'white',
    width: 'fit-content',
    padding: '10px',
    margin: '0 auto 20px auto',
    borderRadius: '7px',
    boxShadow: '1px 1px 2px 0px rgba(0,0,0,0.5)'
  },
  daysCont: {
    display: 'flex',
    position: 'relative',
    width: '5000px',
    minHeight: '400px'
  },
  noSubDay: {
    minWidth: '284px',
    height: '400px'
  },
  noSub: {
    marginTop: '200px',
    position: 'relative',
    left: '4%',
    [theme.breakpoints.up('sm')]: {
      left: '19%',
    },
    [theme.breakpoints.up('md')]: {
      left: '37%'
    },
  },
  arrowBtn: {
    boxSizing: 'border-box',
    border: '1px solid red',
    backgroundColor: 'red',
    '&:hover': {
      border: '1px solid black',
      backgroundColor: 'gold'
    }
  },
  arrow: {
    color: 'white',
  },
  btnCont: {
    boxSizing: 'border-box',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    borderTop: '1px solid rgb(200,200,200)',
    padding: '3px 3px 0px 3px',
  },
  weekDays: {
    maxWidth: '490px',
    overflow: 'hidden',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '387px',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '302px',
    }
  },
  dayDate: {
    marginTop: '-1px'
  },
  dow: {
    fontSize: '12px',
    lineHeight: '12px'
  },
  weatherDate: {
    fontSize: '10px',
    position: 'absolute',
    top: '31px',
    left: '1px'
  },
  obsText: {
    position: 'relative',
    top: '-5px'
  },
  accordionCont: {
    maxWidth: '550px',
    margin: '0 auto'
  },
  forecastsPage: {
    boxSizing: 'border-box',
    padding: '10px 0px',
    overflowY: 'auto',
    height: 'calc(100vh - 64px)'
  }
}));


// Calculate div shift for when week selection changes
const calcWeekChangeDivShift = (bDate, eDate) => {
  let cDate = new Date();
  let clampDate = clamp(cDate, { start: bDate, end: eDate });

  return isSameDay(cDate, clampDate) ? getDay(cDate) - getDay(bDate) : getDay(clampDate) - 1;
};


// Calculate div shift for when contest selection changes
const calcContestChangeDivShift = () => {
  let d = getDay(new Date()) - 1;
  if (d > 4) {
    d = 4;
  } else if (d < 0) {
    d = 0;
  }

  return d;
};



export default function Forecasts({ user, token, contests, forecasts, setForecasts, observations, mostRecent, setLoading, setError, setSuccess, domain }) {
  const [expandedContest, setExpandedContest] = useState(false);
  const [expandedWeek, setExpandedWeek] = useState(false);
  const [weeks, setWeeks] = useState([]);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  const [omitDates, setOmitDates] = useState([]);
  const [divShift, setDivShift] = useState(calcContestChangeDivShift());
  const [divShiftMin, setDivShiftMin] = useState(0);
  const [divShiftMax, setDivShiftMax] = useState(4);
  const [weeksArrObj, setWeeksArrObj] = useState({});
  const [currentContests, setCurrentContests] = useState([]);

  const classes = useStyles();


  // Calls for creation of week html
  useEffect(() => {
    if (expandedContest && start && end) {
      setWeeks(generateWeeks(start, end));
    }
  }, [expandedContest, expandedWeek, divShift, forecasts]);

  // Filter out past contests
  useEffect(() => {
    setCurrentContests(Object.keys(contests).filter(c => differenceInCalendarDays(parseISO(contests[c].endDate), new Date()) > -7));
  }, [contests]);

  // On clicking contest, collapse current contest, expand new one, set:
  //  expandedWeek to current week
  //  divShift to current day
  //  start and end dates to new contest start and end date
  //  omit dates to new contest omissions
  //  expandedContest to new contest
  const handleContestChange = (panel, bDate, eDate, omitArr) => (event, isExpanded) => {
    let contestBegin = parseISO(bDate);
    let contestEnd = parseISO(eDate);
    let lastMonday = subDays(nextMonday(new Date()), 14);
    let beginWeek = getWeek(contestBegin);
    let nowWeek = getWeek(new Date());
    let contestOver = isBefore(contestEnd, new Date());

    // Handles a start date of Saturday
    if (getDay(contestBegin) === 6) {
      beginWeek += 1;
    }

    let weeksObj;
    if (isBefore(lastMonday, contestBegin) || contestOver) {
      weeksObj = breakIntoWeeks(contestBegin, contestEnd);
    } else {
      let skipped = calcSkippedWeeks(lastMonday, contestBegin);
      weeksObj = breakIntoWeeks(lastMonday, contestEnd, `Week ${skipped + 1}`);
    }
    
    let weekNumber = nowWeek - beginWeek + 1;
    let newWeekName = contestOver ? `Week ${Object.keys(weeksObj).length}` : `Week ${weekNumber > 1 ? weekNumber : 1}`;

    let weekMax = weeksObj[newWeekName].length - 1;

    let newDivShift;
    if (weekMax !== 4 && newWeekName === 'Week 1') {
      newDivShift = Math.max(0, calcContestChangeDivShift() - (4 - weekMax));
    } else if (contestOver) {
      newDivShift = weekMax;
    } else {
      newDivShift = calcContestChangeDivShift();
    }

    setWeeksArrObj(weeksObj);
    setDivShiftMin(0);
    setDivShiftMax(weekMax);
    setDivShift(newDivShift);
    setExpandedWeek(newWeekName);
    setStart(bDate);
    setEnd(eDate);
    setOmitDates(omitArr);
    setExpandedContest(isExpanded ? panel : false);
  };

  // On clicking week, collapse current week, expand new one, set expandedWeek and calc new divShift
  const handleWeekChange = (panel, bDate, eDate) => (event, isExpanded) => {
    setExpandedWeek(isExpanded ? panel : false);

    if (isSameDay(bDate, eDate)) {
      setDivShift(0);
      setDivShiftMax(0);
      setDivShiftMin(0);
    } else {
      let newDivShift = calcWeekChangeDivShift(bDate, eDate);
      let newDivShiftMax = weeksArrObj[panel].length - 1;
      setDivShift(newDivShift > newDivShiftMax ? newDivShiftMax : newDivShift);
      setDivShiftMax(newDivShiftMax);
      setDivShiftMin(0);
    }
  };

  // On click of left arrow, decrement divshift to a min of 0 or beginning of contest
  const handleClickDown = () => {
    if (divShift > divShiftMin) {
      setDivShift(prev => prev - 1);
    }
  };
  
  // On click of right arrow, increment divshift to a max of 4 or end of contest
  const handleClickUp = () => {
    if (divShift < divShiftMax) {
      setDivShift(prev => prev + 1);
    }
  };
  
  // Handles generation of day html  
  const generateDays = (daysArr) => {
    let now = new Date();

    return (
      daysArr.reduce((acc,date, index) => {
        let strDate = formatISO(date, { representation: 'date' });
        let weatherDate = formatISO(addDays(date, 2), { representation: 'date' });
        let shift = index * 1000;
        
        if (!omitDates.includes(strDate)) {
          let weatherData, forecastData;
          let rows = [];
          let isWeather = Object.keys(observations).includes(weatherDate);
          let isForecast = Object.keys(forecasts.entries).includes(strDate);
          let isScore = Object.keys(forecasts.scores.daily).includes(strDate);
          
          let canSubmit = (isBefore(now, addHours(date, 20)));
              
          if (isForecast) {
            forecastData = forecasts['entries'][strDate];
            
            rows.push({
              name: forecastData.self ? 'Your Forecast' : 'Default Forecast',
              tempMinLow: forecastData.tMinLow,
              tempMinHigh: forecastData.tMinHigh,
              tempMaxLow: forecastData.tMaxLow,
              tempMaxHigh: forecastData.tMaxHigh,
              precipCategories: [
                forecastData.cat1,
                forecastData.cat2,
                forecastData.cat3,
                forecastData.cat4,
                forecastData.cat5,
                forecastData.cat6
              ],
            });
          } else {
            rows.push({
              name: 'Your forecast',
              tempMinLow: '',
              tempMinHigh: '',
              tempMaxLow: '',
              tempMaxHigh: '',
              precipCategories: ['','','','','',''],
            });
          }

          if (isWeather) {
            weatherData = observations[weatherDate];

            rows.push({ 
              name: <span className={classes.obsText}>Observed Weather <span className={`${classes.secondaryHeading} ${classes.weatherDate}`}>{weatherDate}</span></span>,
              ...weatherData
            });
          } else {
            rows.push({
              name: 'Observed Weather',
              tempMin: '-',
              tempMax: '-',
              precipAmount: '-',
              precipCategory: '-',
            });
          }

          if (isScore) {
            rows.push({
              name: 'Your Penalty Points',
              tempMin: forecasts['scores']['daily'][strDate]['minP'],
              tempMax: forecasts['scores']['daily'][strDate]['maxP'],
              precipAmount: forecasts['scores']['daily'][strDate]['precipP'],
              precipCategory: '-'
            });
          } else {
            rows.push({
              name: 'Your Penalty Points',
              tempMin: '-',
              tempMax: '-',
              precipAmount: '-',
              precipCategory: '-',
            });
          }
              
          acc.push(
            <Day
              key={strDate}
              strDate={strDate}
              rows={rows}
              canSubmit={canSubmit}
              user={user}
              token={token}
              setForecasts={setForecasts}
              setLoading={setLoading}
              setError={setError}
              setSuccess={setSuccess}
              shift={shift}
              domain={domain}
            />
          );
        } else {
          acc.push(
            <div key={strDate} className={classes.noSubDay} style={{ position: 'absolute', left: shift }}>
              <Typography className={`${classes.secondaryHeading} ${classes.noSub}`}>No submission needed for this date.</Typography>
            </div>
          );
        }
        
        return acc;
      }, [])
    );
  };

  // Adds 1 to week name
  const incrementWeekName = (name) => {
    let arr = name.split(' ');
    arr[1] = String(parseInt(arr[1]) + 1);
    return arr.join(' ');
  };

  // Divides date range in business day only weeks
  const breakIntoWeeks = (date1, date2, weekName = 'Week 1') => {
    return (
      eachDayOfInterval({ start: date1, end: date2 }).reduce((acc, day) => {
        if (!isWeekend(day)) {
          if (getDay(day) === 1 && acc[weekName]?.length > 0) {
            weekName = incrementWeekName(weekName);
          }

          if (!Object.keys(acc).includes(weekName)) {
            acc[weekName] = [];
          }
          
          acc[weekName].push(day);
        }

        return acc;
      }, {})
    );
  };

  // Determines number of business weeks between two dates
  const calcSkippedWeeks = (late, early) => {
    if (isWeekend(early)) {
      while (isWeekend(early)) {
        early = addDays(early, 1);
      }
    } else {
      early = subDays(nextMonday(early), 7);
    }   

    return differenceInWeeks(late, early);
  };

  // Handles generation week html
  const generateWeeks = () => {
    return (
      Object.keys(weeksArrObj).map((weekName) => {
        let bDate = formatISO(weeksArrObj[weekName][0], { representation: 'date' });
        let eDate = formatISO(weeksArrObj[weekName][weeksArrObj[weekName].length - 1], { representation: 'date' });
        let currDay = addDays(weeksArrObj[weekName][0], divShift);

        return (
          <Accordion key={weekName} className={expandedWeek === weekName ? classes.weekSelected : classes.unselected} expanded={expandedWeek === weekName} onChange={handleWeekChange(weekName, weeksArrObj[weekName][0], weeksArrObj[weekName][weeksArrObj[weekName].length - 1])}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className={classes.summary}
            >
              <div className={classes.weekPanel}>
                <Typography variant='h3' className={classes.weekHeading}>{weekName}</Typography>
                <Typography variant='h4' className={classes.secondaryHeading}>{bDate} to {eDate}</Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.weekAccordion}>
              <div className={classes.weekDays}>
                {expandedWeek === weekName && <div className={classes.daysCont} style={{ left: `${divShift * -1000}px` }}>{generateDays(weeksArrObj[weekName])}</div>}
              </div>
              <div className={classes.btnCont}>
                {divShift !== 0 && divShift !== divShiftMin ? <Button onClick={handleClickDown} className={classes.arrowBtn}><ArrowBackIcon className={classes.arrow}></ArrowBackIcon></Button> : <div style={{ width: '64px' }}></div>}
                <div>
                  <Typography variant='h3' className={classes.dayDate}>{isSameDay(currDay, new Date()) ? 'Today' : formatISO(currDay, { representation: 'date' })}</Typography>
                  <Typography className={`${classes.secondaryHeading} ${classes.dow}`}>{format(currDay, 'EEEE')}</Typography>
                </div>
                {divShift !== 4 && divShift !== divShiftMax ? <Button onClick={handleClickUp} className={classes.arrowBtn}><ArrowForwardIcon className={classes.arrow}></ArrowForwardIcon></Button> : <div style={{ width: '64px' }}></div>}
              </div>
            </AccordionDetails>
          </Accordion>
        );
      })
    );
  };

  return (
    <div className={classes.forecastsPage}>
      <Typography className={classes.paper} variant='h2'>Dashboard</Typography>
      {mostRecent !== '' && <Typography className={classes.paper} variant='h4'>Latest scored forecast: {formatISO(subDays(parseISO(mostRecent), 2), { representation: 'date' })}</Typography>}
      <div className={classes.accordionCont}>
        { currentContests.length > 0 ? 
          currentContests.map((contestName) => {
            return (
              <Accordion key={contestName} className={expandedContest === contestName ? classes.selected : classes.unselected} expanded={expandedContest === contestName} onChange={handleContestChange(contestName, contests[contestName]['beginDate'], contests[contestName]['endDate'], contests[contestName]['omit'])}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  className={classes.summary}
                >
                  <Typography variant='h2' className={classes.primaryHeading}>{contestName}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordion}>
                  {expandedContest === contestName && weeks}
                </AccordionDetails>
              </Accordion>
            );
          }) : <div id='no-contests' className={classes.paper}>You are not currently enrolled in any active contests. Sign up for one to start participating!</div>
        }
      </div>
    </div>
  );
}

Forecasts.propTypes = {
  user: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  contests: PropTypes.object.isRequired,
  forecasts: PropTypes.object.isRequired,
  setForecasts: PropTypes.func.isRequired,
  observations: PropTypes.object.isRequired,
  mostRecent: PropTypes.string.isRequired,
  setLoading: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  domain: PropTypes.string.isRequired,
};