import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Button, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { saveToken } from '../Helpers/token';


// Create style classes for entire component
const useStyles = makeStyles((theme) => ({
  submit: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  otherBtn: {
    border: '1px solid rgb(100,100,100)'
  },
  btnCont: {
    width: '185px',
    height: '80px',
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: '20px'
  },
  form: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    padding: '35px 25px 25px 25px',
    border: '1px solid black',
    borderRadius: '7px',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white'
  },
  heading: {
    fontSize: '34px'
  },
  divider: {
    background: theme.palette.primary.main,
    marginTop: '20px',
    marginBottom: '20px',
    height: '3px'
  },
  registerPage: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.12)',
  },
  errorMsg: {
    boxSizing: 'border-box',
    position: 'fixed',
    top: '-150px',
    left: '0',
    width: '100vw',
    borderBottom: '1px solid black',
    padding: '20px',
    color: 'white',
    fontWeight: '800',
    backgroundColor: 'rgba(244, 147, 54, 1)',
  },
  visible: {
    top: '0px'
  },
  label: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  innerError: {
    margin: '10px 0px'
  }
}));


// Function to register user by making a request to the cloudflare worker endpoint
async function registerUser(domain, credentials) {
  let results = fetch(`//${domain}/registerUser`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
    .catch(e => {
      console.error(e);
      return ({
        error: true,
        token: null,
        reason: 'An error has occurred. Please try again later.',
        forecasts: null,
        contests: null
      });
    });

  return results;
}



export default function Register({ setToken, setUser, setContests, setForecasts, setLoading, domain }) {
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [reTypedPassword, setReTypedPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [valid, setValid] = useState(false);
  const [tried, setTried] = useState(false);

  // eslint-disable-next-line no-undef
  let url =process.env.PUBLIC_URL + '/login';

  const classes = useStyles();

  // Verifies that whats in inputs is valid for submission
  useEffect(() => {  
    if (username.length > 5 && password.length > 5 && password === reTypedPassword) {
      setValid(true);
    } else {
      setValid(false);
    }
  },[username, password, reTypedPassword]);

  // Causes error message to display for 5 seconds
  useEffect(() => {
    if (showErrorMessage) {
      const timeId = setTimeout(() => {
        setShowErrorMessage(false);
      }, 5000);

      return () => {
        clearTimeout(timeId);
      };
    }
  }, [showErrorMessage]);
  
  
  // On submit, ensure form is properly completed then try to log user in
  // Display error if necessary
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (valid) {
      // Send data to worker to check if user exists, store info, send back token.
      // Receive token or rejection and handle appropriately
      setLoading(true);
      let { error, token, reason, forecasts, contests } = await registerUser(domain, { username, password, reTypedPassword });
      
      if (!error) {
        setToken(token);
        setUser(username);
        setContests(contests);
        setForecasts(forecasts);
        saveToken({ user: username, token: token});
      } else {
        setErrorMessage(reason);
        setShowErrorMessage(true);
      }
      setLoading(false);
    } else {
      setErrorMessage('Your form has not been completed properly. Please try again.');
      setShowErrorMessage(true);
    }

    if (!tried) {
      setTried(true);
    }
  };


  return (
    <div className={classes.registerPage}>
      <div className={`${classes.errorMsg} ${(showErrorMessage || (tried && !valid)) && classes.visible}`}>
        {showErrorMessage && <p className={classes.innerError}>{errorMessage}</p>}
        {(username.length > 5) ? '': <p className={classes.innerError}>Please provide a valid username.</p>}
        {(password.length > 5) ? '': <p className={classes.innerError}>Please provide a valid password.</p>}
        {(password === reTypedPassword) ? '': <p className={classes.innerError}>The passwords that you entered do not match.</p>}
      </div>

      <form className={classes.form}>
        <Typography variant='h1' className={classes.heading}>Please Register</Typography>
        <Divider className={classes.divider} />
        <label className={classes.label}>
          <Typography variant='h4'>Username</Typography>
          <input
            type="text"
            onChange={e => setUserName(e.target.value)}
            placeholder='Min. 6 characters'
          />
        </label>
        <label className={classes.label}>
          <Typography variant='h4'>Password</Typography>
          <input
            type="password"
            onChange={e => setPassword(e.target.value)}
            placeholder='Min. 6 characters'
            required
          />
        </label>
        <label className={classes.label}>
          <Typography variant='h4'>Re-type Password</Typography>
          <input
            type="password"
            onChange={e => setReTypedPassword(e.target.value)}
            placeholder='Re-type Password'
            required
          />
        </label>
        <div className={classes.btnCont}>
          <Button className={classes.submit} type='submit' onClick={handleSubmit}>Create Account</Button>
          <Button className={classes.otherBtn} component={Link} to={url}>Log In</Button>
        </div>
      </form>
    </div>
  );
}

Register.propTypes = {
  setToken: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  setContests: PropTypes.func.isRequired,
  setForecasts: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  domain: PropTypes.string.isRequired,
};