import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  eachDayOfInterval,
  parseISO,
  addDays,
  formatISO,
  isBefore,
  isAfter,
  isSameDay
} from 'date-fns';

import { Button, Chip, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


// Create style classes for entire component
const useStyles = makeStyles((theme) => ({
  paper: {
    boxSizing: 'border-box',
    width: '90%',
    maxWidth: '500px',
    minHeight: '300px',
    height: '629px',
    backgroundColor: 'white',
    padding: '25px',
    borderRadius: '7px',
    boxShadow: '1px 1px 2px 0px rgba(0,0,0,0.5)',
    margin: '20px auto 5px auto'
  },
  error: {
    boxSizing: 'border-box',
    position: 'fixed',
    top: '56px',
    left: '0px',
    padding: '20px',
    width: '100vw',
    minHeight: '40px',
    backgroundColor: theme.palette.error.main,
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
    [theme.breakpoints.up('md')]: {
      width: 'calc(100% + 10px)',
      position: 'absolute',
      top: '-10px',
      left: '-5px'
    },
  },
  submit: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  otherBtn: {
    border: '1px solid rgb(100,100,100)',
    marginTop: '6px',
    marginBottom: '6px',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  input: {
    marginBottom: '10px'
  },
  dblCont: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  dblInput: {
    minWidth: '138px',
    width: '45%',
    height: '24px',
    margin: '0',
    boxSizing: 'border-box',
  },
  dblTypo: {
    minWidth: '138px',
    width: '45%',
    margin: '0 auto'
  }, 
  dblDiv: {
    minWidth: '138px',
    width: '45%',
  },
  omitHead: {
    margin: '15px 0px 6px 0px'
  },
  bottomPortion: {
    height: '200px',
    marginBottom: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflowY: 'auto',
    boxShadow: 'inset 0px 0px 3px 1px rgba(0,0,0,0.12)',
    padding: '7px'
  },
  omitChip: {
    margin: '1px'
  },
  ul: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  divider: {
    background: theme.palette.primary.main,
    marginTop: '20px',
    marginBottom: '20px',
    height: '3px'
  },
  title: {
    fontSize: '34px'
  },
  contestCreatorPage: {
    overflowY: 'auto',
    height: 'calc(100vh - 71px)',
    width: '100%'
  }
}));


// Function to create a contest by making a request to the cloudflare worker endpoint
async function createContest(domain, info) {
  let results = fetch(`//${domain}/createContest`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(info)
  })
    .then(data => data.json())
    .catch((e) => {
      console.error(e);
      return ({
        reason: 'An error has occured. Please try again later.'
      });
    });

  return results;
}



export default function ContestCreator({ user, token, isAdmin = false, setLoading, setError, setSuccess, domain }) {
  const [name, setName] = useState(null);
  const [beginDate, setBeginDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [omitDates, setOmitDates] = useState([]);
  const [newOmitDate, setNewOmitDate] = useState('');
  const [newOmitNumber, setNewOmitNumber] = useState(1);

  const classes = useStyles();


  // On clicking button, add a chip for each day that is to be omitted in the contest date range
  const handleAddOmit = () => {
    if (beginDate && endDate) {
      let contestStart = parseISO(beginDate);
      let contestEnd = parseISO(endDate);

      let startDate = parseISO(newOmitDate);
      let finalDate = addDays(startDate, parseInt(newOmitNumber) - 1);
      let newDates = eachDayOfInterval({ start: startDate, end: finalDate });
      let addDates = [];

      for (const date of newDates) {
        if (isBefore(contestStart, date) && isAfter(contestEnd, date)) {
          let add = true;

          for (const existingDate of omitDates) {
            if (isSameDay(existingDate, date)) {
              add = false;
              break;
            }
          }
          
          if (add) {
            addDates.push(date);
          }
        }
      }

      setOmitDates((prev) => [ ...prev, ...addDates].sort((a,b) => a - b));
    } else {
      setError('Please select the date range for the contest before adding omissions.');
    }

  };


  // On submit, ask db to add the new contest
  const handleSubmit = async e => {
    e.preventDefault();

    if (!isAdmin) {
      setError('You do not have administrative privileges. How did you even get here?!?!');
    } else if (name && beginDate && endDate) {
      let omit = omitDates.map(date => formatISO(date, { representation: 'date' }));

      let info = {
        contest: {
          name,
          beginDate,
          endDate,
          omit
        },
        user: {
          username: user,
          token
        }
      };

      setLoading(true);
      let result = await createContest(domain, info);
      setLoading(false);

      if (result.error) {
        setError(result.reason);
      } else {
        setSuccess(result.reason);
      }
    } else {
      setError('Please fill out required fields before submitting.');
    }
  };


  // On clicking 'X' of omit chip, delete that date from the omission array
  const handleDelete = (dateToDelete) => () => {
    setOmitDates((days) => days.filter((day) => formatISO(day, {representation:'date'}) !== dateToDelete));
  };


  return (
    <div className={classes.contestCreatorPage}>
      <div className={classes.paper}>
        <Typography className={classes.title} variant='h2'>ContestCreator</Typography>
        <Divider className={classes.divider} />
        <Typography variant='h3'>Contest Name</Typography>
        <input className={classes.input} type="text" onChange={e => setName(e.target.value)} />
        
        <div className={classes.dblCont}>
          <div className={classes.dblDiv}>
            <Typography className={classes.dblTypo} variant='h3'>Begin Date</Typography>
            <input className={classes.dblInput} type="date" onChange={e => setBeginDate(e.target.value)} />
          </div>
          
          <div className={classes.dblDiv}>
            <Typography className={classes.dblTypo} variant='h3'>End Date</Typography>
            <input className={classes.dblInput} type="date" onChange={e => setEndDate(e.target.value)} />
          </div>
        </div>
        
        <Typography className={classes.omitHead} variant='h3'>Omit Dates (optional)</Typography>
          
        <div className={classes.dblCont}>
          <div className={classes.dblDiv}>
            <Typography className={classes.dblTypo} variant='h4'>Start Date</Typography>
            <input className={classes.dblInput} type="date" onChange={e => setNewOmitDate(e.target.value)} />
          </div>
        
          <div className={classes.dblDiv}>
            <Typography className={classes.dblTypo} variant='h4'>Number of days</Typography>
            <input
              className={classes.dblInput}
              type="number"
              onChange={e => setNewOmitNumber(e.target.value)}
              onWheel={(e) => e.target.blur()}
            >
              
            </input>
          </div>
          
        </div>

        <Button className={classes.otherBtn} onClick={handleAddOmit}>Add Omission</Button>
      
        <div className={classes.bottomPortion}>
          <ul className={classes.ul}>
            {omitDates.map(day => {
              let date = formatISO(day, { representation: 'date' });
        
              return (
                <li key={date} className={classes.li}>
                  <Chip
                    label={date}
                    onDelete={handleDelete(date)}
                    className={classes.omitChip}
                  />
                </li>
              );
            })}
          </ul>
        </div>
        <Button className={classes.submit} type="submit" onClick={handleSubmit}>Create Contest</Button>
      </div>
    </div>
  );
}

ContestCreator.propTypes = {
  user: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool,
  setLoading: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  domain: PropTypes.string.isRequired,
};