/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  InputLabel,
  FormControl,
  FormHelperText,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';

import '../Styles/Day.scss';


// Create style classes for entire component
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  table: {
    border: '1px solid rgba(0,0,0,0.12)',
    borderTop: 'none',
    marginBottom: '20px'
  },
  tableContainer: {
    width: 'calc(100% - 6px)',
    margin: '0 auto'
  },
  margin: {
    height: theme.spacing(3),
  },
  accordion: {
    flexDirection: 'column'
  },
  slider: {
    width: '80%'
  },
  tl: {
    textAlign: 'center',
    borderRight: '1px solid black',
    borderBottom: 'none',
    paddingLeft: '4px',
    paddingRight: '4px',
    maxWidth: '55px'
  },
  tt: {
    textAlign: 'center',
    borderBottom: '1px solid black',
    borderLeft: 'none',
    transform: 'rotate(45deg)'
  },
  ttb: {
    borderBottom: 'none',
    borderRight: 'none',
    paddingLeft: '4px',
    paddingRight: '4px',
    maxWidth: '55px'
  },
  tc: {
    borderLeft: '1px solid rgba(0,0,0,0.12)',
    paddingLeft: '6px',
    paddingRight: '6px'
  },
  to: {
    width: '42px'
  },
  ul: {
    listStyleType: 'none',
    padding: '0',
    width: 'fit-content',
    margin: '0 auto'
  },
  li: {
    textAlign: 'left'
  },
  narrowCol: {
    maxWidth: '42px',
    padding: '0px'
  },
  centerCol: {
    maxWidth: '76px',
    margin: '0 auto'
  },
  adjLeft: {
    position: 'relative',
    left: '-10px'
  },
  trHide: {
    overflow: 'hidden'
  },
  inputs: {
    width: '110px',
    marginTop: '6px',
    marginBottom: '6px',
    position: 'relative'
  },
  submit: {
    color: 'white',
    marginBottom: '7px',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  },
  helper: {
    color: 'red',
    textAlign: 'center',
    position: 'absolute',
    top: '-30px',
    left: '-117px',
    right: '120px',
    backgroundColor: 'white',
    border: '1px solid red',
    borderRadius: '5px',
    zIndex: '10',
    fontSize: '13px',
    padding: '6px'
  },
  rMsg: {
    position: 'fixed',
    boxSizing: 'border-box',
    top: '56px',
    left: '0px',
    padding: '10px',
    width: '100vw',
    backgroundColor: theme.palette.error.main,
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
    zIndex: '11'
  },
  day: {
    position: 'absolute',
    minWidth: '490px',
    maxHeight: '400px',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      minWidth: '387px',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '302px',
    }
  }
}));


// Function to submit a forecast to be saved by making a request to the cloudflare worker endpoint
async function submitForecast(domain, obj) {
  let results = fetch(`//${domain}/submitForecast`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
  })
    .then(data => data.json())
    .catch(e => {
      console.error(e);
      return ({
        success: false,
        msg: 'An error has occurred. Please try again later.'
      });
    });

  return results;
}



export default function Day({ strDate, rows, canSubmit, user, token, setForecasts, setLoading, setError, setSuccess, shift, domain }) {
  const [precipTotal, setPrecipTotal] = useState(rows[0].precipCategories[0] === '' ? 0 : rows[0].precipCategories.reduce((acc,val) => acc + val, 0));
  const [isError, setIsError] = useState({
    tMinLow: {
      error: false,
      msg: ''
    },
    tMinHigh: {
      error: false,
      msg: ''
    },
    tMaxLow: {
      error: false,
      msg: ''
    },
    tMaxHigh: {
      error: false,
      msg: ''
    },
    precip: {
      error: false,
      msg: ''
    }
  });
  const [inputState, setInputState] = useState({
    tMinLow: rows[0].tempMinLow === '' ? 0 : rows[0].tempMinLow,
    tMinHigh: rows[0].tempMinHigh === '' ? 20 : rows[0].tempMinHigh,
    tMaxLow: rows[0].tempMaxLow === '' ? 80 : rows[0].tempMaxLow,
    tMaxHigh: rows[0].tempMaxHigh === '' ? 100 : rows[0].tempMaxHigh,
    cat1: rows[0].precipCategories[0] === '' ? 0 : rows[0].precipCategories[0],
    cat2: rows[0].precipCategories[1] === '' ? 0 : rows[0].precipCategories[1],
    cat3: rows[0].precipCategories[2] === '' ? 0 : rows[0].precipCategories[2],
    cat4: rows[0].precipCategories[3] === '' ? 0 : rows[0].precipCategories[3],
    cat5: rows[0].precipCategories[4] === '' ? 0 : rows[0].precipCategories[4],
    cat6: rows[0].precipCategories[5] === '' ? 0 : rows[0].precipCategories[5],
  });

  const classes = useStyles();

  // Checks if current temperature inputs are valid for submission
  useEffect(() => {
    let arr = ['tMinLow', 'tMinHigh', 'tMaxLow', 'tMaxHigh'];
    let errors = {};

    arr.forEach((variable) => {
      let upperBound, lowerBound, errMsg;
      let value = inputState[variable];
      if (variable === 'tMinLow') {
        lowerBound = -999;
        upperBound = inputState.tMinHigh;
        errMsg = '"Lower Bound" must be lower than "Upper Bound".';
      } else if (variable === 'tMinHigh') {
        lowerBound = inputState.tMinLow;
        upperBound = 999;
        errMsg = 'Min. Temp. "Upper Bound" must be between "Lower Bound" values.';
      } else if (variable === 'tMaxLow') {
        lowerBound = -999;
        upperBound = inputState.tMaxHigh;
        errMsg = 'Max. Temp. "Lower Bound" must be between "Upper Bound" values.';
      } else if (variable === 'tMaxHigh') {
        lowerBound = inputState.tMaxLow;
        upperBound = 999;
        errMsg = '"Upper Bound" must be higher than "Lower Bound" value.';
      }
      
      if (value > parseInt(lowerBound) && value < parseInt(upperBound)) {
        errors[variable] = {
          error: false,
          msg: ''
        };
      } else {
        errors[variable] = {
          error: true,
          msg: errMsg
        };
      }
    });

    setIsError({
      ...isError,
      ...errors
    });

  }, [inputState]);


  // On click of button, checks if submission is valid then requests the db to store the forecast
  const handleSubmit = async (e) => {
    let errors = Object.values(isError).reduce((acc,input) => {
      if (!acc) {
        return input.error ? true : false;
      } else {
        return acc;
      }
    }, false);

    if (precipTotal !== 100) {
      errors = true;
      setIsError({
        ...isError,
        precip: {
          error: true,
          msg: 'Sum of Precip. Cats. must equal 100.'
        }
      });
    }
    
    if (errors) {
      setError('Please double check your submission. Something was wrong.');
    } else {
      setLoading(true);
      let results = await submitForecast(domain, {
        ...inputState,
        self: true,
        strDate,
        user,
        token,
      });
      
      
      if (results.success) {
        setSuccess(results.msg);
        setForecasts(results.newForecasts);
      } else {
        setError(results.msg);
      }
      setLoading(false);
    }
  };

  // On change of any precip select, checks if the sum of the categories, if valid then it stores the selection in state
  const handleChange = (value, variable) => {
    value = parseInt(value);

    if (variable.includes('cat')) {
      let pSum = inputState.cat1 + inputState.cat2 + inputState.cat3 + inputState.cat4 + inputState.cat5 + inputState.cat6 - inputState[variable] + value;
      
      if (pSum > 100) {
        setIsError({
          ...isError,
          precip: {
            error: true,
            msg: 'Category percentage sum can not be more than 100.'
          }
        });
      } else {
        setIsError({
          ...isError,
          precip: {
            error: false,
            msg: ''
          }
        });
        
        setPrecipTotal(pSum),
        setInputState({
          ...inputState,
          [variable]: parseInt(value)
        });
      }
    } else {
      setInputState({
        ...inputState,
        [variable]: value
      });
    }
  };

  // Handles generation of selects for form
  const generateSelects = () => {
    const data = [
      {
        name: 'cat1',
        amount: 'Cat.1:  0.00 - Trace'
      },
      {
        name: 'cat2',
        amount: 'Cat.2:  0.01 - 0.09'
      },
      {
        name: 'cat3',
        amount: 'Cat.3:  0.10 - 0.24'
      },
      {
        name: 'cat4',
        amount: 'Cat.4:  0.25 - 0.49'
      },
      {
        name: 'cat5',
        amount: 'Cat.5:  0.50 - 0.99'
      },
      {
        name: 'cat6',
        amount: 'Cat.6:  >= 1.00'
      }
    ];

    return (
      data.map((cat, index) => {
        return (
          <FormControl key={cat.name + index + strDate} className={classes.inputs}>
            <InputLabel id={cat.name}>{cat.amount}</InputLabel>
            <Select
              className="precip-select"
              labelId={cat.name}
              value={inputState[cat.name]}
              onChange={e => handleChange(e.target.value, cat.name)}
              error={isError.precip.error}
            >
              <MenuItem value={0}>0</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
              <MenuItem value={40}>40</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={60}>60</MenuItem>
              <MenuItem value={70}>70</MenuItem>
              <MenuItem value={80}>80</MenuItem>
              <MenuItem value={90}>90</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
            {(cat.name === 'cat3' && isError.precip.error) && <FormHelperText className={classes.helper}>{isError.precip.msg}</FormHelperText>}
          </FormControl>
        );
      })
    );
  };

  // Handles generation of form for page
  const renderForm = () => {
    let selects = generateSelects();
    
    return (
      [
        <FormControl key={'maxs'}>
          <TextField
            className={classes.inputs}
            label="Upper Bound"
            type="number"
            value={inputState.tMaxHigh}
            error={isError.tMaxHigh.error}
            onChange={e => handleChange(e.target.value, 'tMaxHigh')}
            InputLabelProps={{
              shrink: true,
            }}
            onWheel={(e) => e.target.blur()}
          />
          <TextField
            className={classes.inputs}
            label="Lower Bound"
            type="number"
            value={inputState.tMaxLow}
            error={isError.tMaxLow.error}
            onChange={e => handleChange(e.target.value, 'tMaxLow')}
            InputLabelProps={{
              shrink: true,
            }}
            onWheel={(e) => e.target.blur()}
          />
          {(isError.tMaxLow.error || isError.tMaxHigh.error) && <FormHelperText className={classes.helper}>
            <div>
              {isError.tMaxHigh.msg}
            </div>
            <hr/>
            <div>
              {isError.tMaxLow.msg}
            </div>
          </FormHelperText>}
        </FormControl>,
        <FormControl key={'mins'}>
          <TextField
            className={classes.inputs}
            label="Upper Bound"
            type="number"
            value={inputState.tMinHigh}
            error={isError.tMinHigh.error}
            onChange={e => handleChange(e.target.value, 'tMinHigh')}
            InputLabelProps={{
              shrink: true,
            }}
            onWheel={(e) => e.target.blur()}
          />
          <TextField
            className={classes.inputs}
            label="Lower Bound"
            type="number"
            value={inputState.tMinLow}
            error={isError.tMinLow.error}
            onChange={e => handleChange(e.target.value, 'tMinLow')}
            InputLabelProps={{
              shrink: true,
            }}
            onWheel={(e) => e.target.blur()}
          />
          {(isError.tMinLow.error || isError.tMinHigh.error) && <FormHelperText className={classes.helper}>
            <div>
              {isError.tMinHigh.msg}
            </div>
            <hr/>
            <div>
              {isError.tMinLow.msg}
            </div>
          </FormHelperText>}
        </FormControl>,
        ...selects
      ]
    );
  };

  // Handles generation of completed forcast if a form is not needed
  const renderCompletedForecast = () => {
    return (
      [
        `${rows[0].tempMaxLow} - ${rows[0].tempMaxHigh}`,
        `${rows[0].tempMinLow} - ${rows[0].tempMinHigh}`,
        rows[0].precipCategories[0] === '' ? '-' : `${rows[0].precipCategories[0]}%`,
        rows[0].precipCategories[1] === '' ? '-' : `${rows[0].precipCategories[1]}%`,
        rows[0].precipCategories[2] === '' ? '-' : `${rows[0].precipCategories[2]}%`,
        rows[0].precipCategories[3] === '' ? '-' : `${rows[0].precipCategories[3]}%`,
        rows[0].precipCategories[4] === '' ? '-' : `${rows[0].precipCategories[4]}%`,
        rows[0].precipCategories[5] === '' ? '-' : `${rows[0].precipCategories[5]}%`
      ]
    );
  };

  let forecastDivs = canSubmit ? renderForm() : renderCompletedForecast();

  return (
    <div className={classes.day} style={{ left: shift }}>
      <TableContainer className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow className={classes.trHide}>
              <TableCell className={classes.ttb} component="th" scope="row"><Typography variant='h5'></Typography></TableCell>
              <TableCell className={`${classes.tt} ${classes.narrowCol} ${classes.adjLeft}`} component="th" scope="row"><Typography variant='h5'>{rows[1].name}</Typography></TableCell>
              <TableCell className={classes.tt} component="th" scope="row"><Typography variant='h5' className={classes.centerCol}>{rows[0].name}</Typography></TableCell>
              <TableCell className={`${classes.tt} ${classes.narrowCol}`} component="th" scope="row"><Typography variant='h5'>{rows[2].name}</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell className={classes.tl}><Typography variant='h5'>Max. Temp. (&deg;F)</Typography></TableCell>
              <TableCell className={`${classes.tc} ${classes.to}`} align="center">{rows[1].tempMax}</TableCell>
              <TableCell className={classes.tc} align="center"><div className="mins-cont">{forecastDivs[0]}</div></TableCell>
              <TableCell className={`${classes.tc} ${classes.to}`} align="center">{rows[2].tempMax}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tl}><Typography variant='h5'>Min. Temp. (&deg;F)</Typography></TableCell>
              <TableCell className={`${classes.tc} ${classes.to}`} align="center">{rows[1].tempMin}</TableCell>
              <TableCell className={classes.tc} align="center"><div className="maxs-cont">{forecastDivs[1]}</div></TableCell>
              <TableCell className={`${classes.tc} ${classes.to}`} align="center">{rows[2].tempMin}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tl}><Typography variant='h5'>Precip. Amount (in.)</Typography></TableCell>
              <TableCell className={`${classes.tc} ${classes.to}`} align="center">{rows[1].precipAmount}</TableCell>
              <TableCell className={classes.tc} align="center" rowSpan={2}>
                <div className={classes.ulCont}>
                  <ul className={classes.ul}>
                    {forecastDivs.slice(2).map((percentage, index) => {
                      return <li className={classes.li} key={percentage + index + strDate}>{canSubmit ? percentage : `Cat. ${index + 1}:  ${percentage}`}</li>;
                    })}
                  </ul>
                </div>
              </TableCell>
              <TableCell className={`${classes.tc} ${classes.to}`} align="center" rowSpan={2}>{rows[2].precipAmount}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.tl}><Typography variant='h5'>Precip. Cat. (1-6)</Typography></TableCell>
              <TableCell className={`${classes.tc} ${classes.to}`} align="center">{rows[1].precipCategory}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {canSubmit && <div>
        <Button className={classes.submit} onClick={handleSubmit}>Submit</Button>
      </div>}
    </div>
  );
}

Day.propTypes = {
  strDate: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
  canSubmit: PropTypes.bool.isRequired,
  user: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  setForecasts: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  shift: PropTypes.number.isRequired,
  domain: PropTypes.string.isRequired,
};