import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


// Create style classes for entire component
const useStyles = makeStyles((theme) => ({
  resMsg: {
    boxSizing: 'border-box',
    position: 'fixed',
    top: '56px',
    left: '0px',
    padding: '10px',
    width: '100vw',
    backgroundColor: theme.palette.error.main,
    borderTop: '1px solid black',
    borderBottom: '1px solid black'
  },
  submit: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    },
    marginTop: '15px'
  },
  form: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    padding: '25px',
    boxShadow: '1px 1px 2px 0px rgba(0,0,0,0.5)',
    borderRadius: '7px',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
  },
  divider: {
    background: theme.palette.primary.main,
    marginTop: '20px',
    height: '3px'
  },
  title: {
    fontSize: '34px'
  },
  label: {
    marginTop: '10px',
    marginBottom: '10px',
  }
}));


// Function to change user settings by making a request to the cloudflare worker endpoint
async function changeSettings(domain, obj) {
  let results = fetch(`//${domain}/changeSettings`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
  })
    .then(data => data.json())
    .catch(e => {
      console.error(e);
      return 'An error has occurred. Please try again later.';
    });

  return results;
}



export default function Settings({ user, setUser, token, setLoading, setError, setSuccess, domain }) {
  const [resMsg, setResMsg] = useState(false);
  const [newUsername, setNewUserName] = useState(user);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [reTypedPassword, setReTypedPassword] = useState('');
  
  const classes = useStyles();


  // On submit, ensure form is properly completed then try to log user in
  // Display error if necessary
  const handleSubmit = async e => {
    e.preventDefault();

    if (newPassword !== '' && reTypedPassword !== newPassword) {
      setResMsg('The new passwords you supplied do not match.');
    } else if (newPassword !== '' && newPassword.length < 6) {
      setResMsg('The new passwords you supplied are too short. They must be at least 6 characters.');
    } else if (newUsername !== '' && newUsername < 6) {
      setResMsg('The new username you supplied is too short. It must be at least 6 characters.');
    } else if (currentPassword === '') {
      setResMsg('You must enter your current password to make any changes.');
    } else {
      setLoading(true);
      let response = await changeSettings(domain, { user, token, currentPassword, newUsername, newPassword });
  
      if (!response.error) {
        setUser(newUsername);
        setSuccess(response.msg);
      } else {
        setError(false);
        setError(response.msg);
      }

      setResMsg(false);
      setLoading(false);
    }
  };


  return (
    <div id='settings-page'>
      {resMsg && <div className={classes.resMsg}>{resMsg}</div>}

      <form className={classes.form}>
        <Typography variant='h1' className={classes.title}>Settings</Typography>
        <Divider className={classes.divider} />

        <label className={classes.label}>
          <Typography variant='h4'>Username</Typography>
          <input type="text" onChange={e => setNewUserName(e.target.value)} placeholder={user} />
        </label>

        <label className={classes.label}>
          <Typography variant='h4'>Password</Typography>
          <input type="password" onChange={e => setNewPassword(e.target.value)} placeholder='Min. 6 characters' />
        </label>

        <label className={classes.label}>
          <Typography variant='h4'>Re-type Password</Typography>
          <input type="password" onChange={e => setReTypedPassword(e.target.value)} placeholder='Re-type password' />
        </label>

        <label className={classes.label}>
          <Typography variant='h4'>Current Password</Typography>
          <input type="password" onChange={e => setCurrentPassword(e.target.value)} placeholder='Required' />
        </label>

        <Button className={classes.submit} type="submit" onClick={handleSubmit}>Change Settings</Button>
      </form>
    </div>
  );
}

Settings.propTypes = {
  setLoading: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  user: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  setError: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  domain: PropTypes.string.isRequired,
};