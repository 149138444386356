import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';

import { Button, Divider, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


// Create style classes for entire component
const useStyles = makeStyles((theme) => ({
  paper: {
    boxSizing: 'border-box',
    backgroundColor: 'white',
    width: '90%',
    maxWidth: '520px',
    margin: '20px auto 5px auto',
    height: 'fit-content',
    padding: '25px',
    borderRadius: '7px',
    boxShadow: '1px 1px 2px 0px rgba(0,0,0,0.5)',
    overflowY: 'auto'
  },
  error: {
    boxSizing: 'border-box',
    position: 'fixed',
    top: '56px',
    left: '0px',
    padding: '10px',
    width: '100vw',
    backgroundColor: theme.palette.error.main,
    borderTop: '1px solid black',
    borderBottom: '1px solid black'
  },
  delBtn: {
    color: 'black',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    },
    marginTop: '10px'
  },
  otherBtn: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    },
    marginRight: '5px',
    marginTop: '10px'
  },
  contest: {
    marginTop: '20px',
    marginBottom: '20px'
  },
  divider: {
    background: theme.palette.primary.main,
    marginTop: '20px',
    marginBottom: '20px',
    height: '3px'
  },
  title: {
    fonSize: '34px'
  },
  secondaryDivider: {
    height: '2px',
    width: '300px',
    margin: '0 auto'
  },
  contestsPage: {
    overflowY: 'auto',
    height: 'calc(100vh - 71px)',
    width: '100%'
  }
}));


// Function to get list of contests by making a request to the cloudflare worker endpoint
async function getContests(domain) {
  let results = fetch(`//${domain}/getContests`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(data => data.json())
    .catch(e => {
      console.error(e);
      return 'A server error has occurred. Please try again later.';
    });

  return results;
}


// Function to enroll a user in a contest by making a request to the cloudflare worker endpoint
async function enrollInContest(domain, obj) {
  let results = fetch(`//${domain}/enrollContest`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
  })
    .then(data => data.json())
    .catch(e => {
      console.error(e);
      return ({
        contests: {},
        error: true
      });
    });

  return results;
}


// Function to delete a contest from the database and all users by making a request to the cloudflare worker endpoint
async function deleteContest(domain, obj) {
  let results = fetch(`//${domain}/deleteContest`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
  })
    .then(data => data.json())
    .catch(e => {
      console.error(e);
      return ({
        error: true
      });
    });

  return results;
}



export default function Contests({ userContests, setUserContests, setUserForecasts, user, token, isAdmin = false, setLoading, setError, setSuccess, domain }) {
  const [contestList, setContestList] = useState([]);
  const [updateList, setUpdateList] = useState(false);
  const [contestDivs, setContestDivs] = useState([]);
  const [updateDivs, setUpdateDivs] = useState(false);
  const [serverProblem, setServerProblem] = useState(false);

  const classes = useStyles();


  // Updates the list of contests
  useEffect(() => {
    setLoading(true);
    getContests(domain)
      .then(data => {
        if (typeof data === 'string') {
          setError(data);
          setServerProblem(true);
        } else {
          setContestList(data);
        }
        
        setLoading(false);
      });
  }, [updateList]);
      

  // Creates and updates html for each contest in list
  useEffect(() => {
    setContestDivs(contestList.map((contest, index) => {
      return (
        <Fragment key={index}>
          {index === 0 ? <></> : <Divider className={`${classes.divider} ${classes.secondaryDivider}`} />}
          <li className={classes.contest} key={contest.name}>
            <Typography variant='h3'>{contest.name}</Typography>
            <Typography variant='body1'>Begins: {contest.beginDate}</Typography>
            <Typography variant='body1'>Ends: {contest.endDate}</Typography>
            {Object.keys(userContests).includes(contest.name) ? <Typography variant='body2'>You are enrolled</Typography> : <>
              <Typography variant='body2'>Not enrolled</Typography>
              <Button className={classes.otherBtn} value={JSON.stringify(contest)} onClick={handleEnroll}>Join</Button>
            </>}
            {isAdmin && <Button className={classes.delBtn} onClick={() => handleDelete(contest.name)}>Delete Contest</Button>}
          </li>
        </Fragment>
      );
    }));
  }, [contestList, updateDivs]);


  // On clicking button, ask db to update user with new contest info
  const handleEnroll = async (event) => {
    setLoading(true);

    let contest = JSON.parse(event.currentTarget.value);

    let results = await enrollInContest(domain, { contest, user, token });

    if (results.error) {
      setError('There was an error during the last enrollment attempt. Please try again later.');
    } else {
      setUserContests(results.contests);
      setUserForecasts(results.forecasts);
      setUpdateDivs(!updateDivs);
      setSuccess('You have successfully joined!');
    }

    setLoading(false);
  };


  // On clicking button, ask db to remove contest from db and all users
  const handleDelete = async (name) => {
    if (isAdmin) {
      setLoading(true);
      let deleteObj = {
        contest: name,
        user: {
          user,
          token
        }
      };
      
      let results = await deleteContest(domain, deleteObj);
      
      if (results.error) {
        setError(results.reason);
      } else {
        let newState = userContests;
        delete newState[name];
        
        setUserContests(newState);
        setUpdateList(!updateList);
        setSuccess(`'${name}' was successfully deleted.`);
      }

      setLoading(false);
    }
  };

  
  return (
    <div className={classes.contestsPage}>
      <div className={classes.paper}>
        <Typography variant='h1' className={classes.title}>Contests</Typography>
        <Divider className={classes.divider} />
        <ul>
          {contestDivs.length > 0 ? contestDivs : <div id='no-contests'>{serverProblem ? 'A server error has occurred. Please try again later.' : 'There are currently no scheduled contests.'}</div>}
        </ul>
      </div>
    </div>
  );
}

Contests.propTypes = {
  userContests: PropTypes.object.isRequired,
  setUserContests: PropTypes.func.isRequired,
  setUserForecasts: PropTypes.func.isRequired,
  user: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool,
  setLoading: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  domain: PropTypes.string.isRequired,
};