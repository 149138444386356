import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { parseISO, isMonday, isTuesday, isFuture } from 'date-fns';

import { Button, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


// Create style classes for entire component
const useStyles = makeStyles((theme) => ({
  resMsg: {
    boxSizing: 'border-box',
    position: 'fixed',
    top: '56px',
    left: '0px',
    padding: '10px',
    width: '100vw',
    backgroundColor: theme.palette.error.main,
    borderTop: '1px solid black',
    borderBottom: '1px solid black'
  },
  submit: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    },
    marginTop: '15px'
  },
  form: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    padding: '25px',
    boxShadow: '1px 1px 2px 0px rgba(0,0,0,0.5)',
    borderRadius: '7px',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
  },
  divider: {
    background: theme.palette.primary.main,
    marginTop: '20px',
    height: '3px'
  },
  title: {
    fontSize: '34px'
  },
  label: {
    marginTop: '10px',
    marginBottom: '10px',
  }
}));


// Function to alter weather observation by making a request to the cloudflare worker endpoint
async function editObservation(domain, obj) {
  let results = fetch(`//${domain}/editObservation`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(obj)
  })
    .then(data => data.json())
    .catch(e => {
      console.error(e);
      return 'An error has occurred. Please try again later.';
    });

  return results;
}



// eslint-disable-next-line no-unused-vars
export default function WeatherDataChanger({ user, isAdmin, token, setForecasts, setUpdateObservations, setLoading, setError, setSuccess, domain }) {
  const [date, setDate] = useState();  
  const [min, setMin] = useState();
  const [max, setMax] = useState();
  const [precip, setPrecip] = useState();  

  const classes = useStyles();


  // On submit, ensure form is properly completed then try to change observation
  // Display error if necessary
  const handleSubmit = async e => {
    e.preventDefault();

    if (!isAdmin) {
      setError('You do not have administrative privileges. You can not edit weather observations.');
    } else if (!date) {
      setError('Please enter a date.');
    } else if (isMonday(parseISO(date)) || isTuesday(parseISO(date))) {
      setError('No weather observations are collected for that day of the week.');
    } else if (isFuture(parseISO(date))) {
      setError('You can not edit future weather observations.');
    } else if (!min) {
      setError('Please enter a minimum temperature.');
    } else if (!max) {
      setError('Please enter a maximum temperature.');
    } else if (min >= max) {
      setError('Minimum temperature must be lower than maximum temperature.');
    } else if (!precip) {
      setError('Please enter a precipitation amount.');
    } else {
      setLoading(true);
      let results = await editObservation(domain, { user, token, min, max, date, precip });
      
      if (results.error) {
        setError(results.msg);
      } else {
        setForecasts(results.forecasts);
        setUpdateObservations(prev => !prev);
        setSuccess(results.msg);
      }
      
      setLoading(false);
    }
  };


  return (
    <div className={classes.WeatherDataChanger}>
      <form className={classes.form}>
        <Typography variant='h1' className={classes.title}>Weather Observation Editor</Typography>
        <Divider className={classes.divider} />

        <label className={classes.label}>
          <Typography variant='h4'>Date</Typography>
          <input type="date" onChange={e => setDate(e.target.value)} />
        </label>

        <label className={classes.label}>
          <Typography variant='h4'>Min. Temperature</Typography>
          <input type="number" onChange={e => setMin(e.target.value)} placeholder='Fahrenheit' onWheel={(e) => e.target.blur()} />
        </label>

        <label className={classes.label}>
          <Typography variant='h4'>Max. Temperature</Typography>
          <input type="number" onChange={e => setMax(e.target.value)} placeholder='Fahrenheit' onWheel={(e) => e.target.blur()} />
        </label>

        <label className={classes.label}>
          <Typography variant='h4'>Precip. Amount</Typography>
          <input type="number" onChange={e => setPrecip(e.target.value)} min={0} step='0.01' placeholder='Inches' onWheel={(e) => e.target.blur()} />
        </label>

        <Button className={classes.submit} type="submit" onClick={handleSubmit}>Change Settings</Button>
      </form>
    </div>
  );
}

WeatherDataChanger.propTypes = {
  user: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  setForecasts: PropTypes.func.isRequired,
  setUpdateObservations: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setSuccess: PropTypes.func.isRequired,
  domain: PropTypes.string.isRequired,
};