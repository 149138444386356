const checkToken = async (domain, credentials) => {
  let results = fetch(`//${domain}/verifyToken`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
    .catch(e => {
      console.error(e);
      return ({
        verified: false
      });
    });

  return results;
};

export const saveToken = (obj) => {
  localStorage.setItem('userInfo', JSON.stringify(obj));
};

export const getToken = async (domain) => {
  try {
    const infoString = localStorage.getItem('userInfo');
    const info = infoString === '' ? '' : JSON.parse(infoString);
  
    if (info?.token && info?.user) {
      let result = await checkToken(domain, info);
  
      if (result.verified) {
        let newInfo = { user: info.user, token: result.token };
        saveToken(newInfo);
  
        newInfo['forecasts'] = result.forecasts;
        newInfo['contests'] = result.contests;

        if ('isAdmin' in result) {
          newInfo['isAdmin'] = result.isAdmin;
        }

        return newInfo;
      } else {
        localStorage.setItem('userInfo', '');
      }
    }
  } catch (e) {
    console.error(e);
  }

  return { 
    user: '',
    token: '',
    forecasts: {},
    contests: {}
  };
};